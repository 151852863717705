<template>
  <div class="lesson module1-part2-lesson3">
    <div class="app-block">
      <div class="app-block-title">
        {{ $t('module1.part2.aides.aideLocale.title') }}
      </div>
      <div class="app-block-content">
        <section-fiche-aide
          :icon="require('@/assets/module1/part2/lesson/collectivite-ico.svg')"
          :label="$t('module1.part2.aides.aideLocale.collectiviteTitle')"
        >
          <template #default>
            <p>{{ $t('module1.part2.aides.aideLocale.collectivite') }}</p>
          </template>
        </section-fiche-aide>
        <section-fiche-aide
          :icon="require('@/assets/module1/part2/lesson/obtention-ico.svg')"
          :label="$t('module1.part2.aides.aideLocale.dispoTitle')"
        >
          <template #default>
            <div class="checklist">
              <div class="item">
                <p v-html="$t('module1.part2.aides.aideLocale.dispo1')"></p>
              </div>
              <div class="item">
                <p v-html="$t('module1.part2.aides.aideLocale.dispo2')"></p>
              </div>
            </div>
          </template>
        </section-fiche-aide>
      </div>
    </div>

    <app-button-layout class="next-button" @click="$emit('next-tab')">
      {{ $t('global.navigation.resume') }}
    </app-button-layout>
  </div>
</template>

<script>
import SectionFicheAide from '@/components/training/module1/part2/SectionFicheAide.vue'
import AppButtonLayout from '@/components/buttons/AppButtonLayout.vue'

export default {
  name: 'Module1Part2Lesson3',
  components: { AppButtonLayout, SectionFicheAide }
}
</script>

<style scoped lang="scss">
.module1-part2-lesson3 {
  .app-block-content {
    display: flex;
    flex-direction: column;
    gap: $space-xl;
    align-items: center;
  }
}
</style>
